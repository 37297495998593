<template>
  <div>
    <div class="container">
      <div class="sctp-bg-white sctp-pad15 sctp-mar-b15 sctp-flex subMenu-wrap">
        <template v-for="menu in menus">
          <div :key="menu.name" :id="menu.name">
            <router-link :to="menu.url">{{ menu.name }}</router-link>
          </div>
        </template>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'favorite',
  data() {
    return {
      menus: [
        {name: '工作经历', url: '/user/resume/work-experience'},
        {name: '教育经历', url: '/user/resume/ed-experience'},
        {name: '获奖经历', url: '/user/resume/awards-experience'}
      ]
    }
  },
  computed: {},
  methods: {}
}
</script>

<style scoped lang="scss">
.container {
  .subMenu-wrap {
    height: 49px;
    font-size: 15px;
    font-weight: 700;

    > div {
      border-right: 1px solid #e5e5e5;
      padding: 0 30px;
    }
  }

  .router-link-active {
    color: #FF9900 !important;
  }
}
</style>
